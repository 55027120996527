@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply dark:bg-gray-900 bg-white min-h-screen max-h-screen;
  min-height: -webkit-fill-available;
  max-height: -webkit-fill-available;
}

.absolute-center {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%) translateY(-15%);
  z-index: 10;
}

.user-score:first-child {
  margin-left: auto;
}

.user-score:last-child {
  margin-right: auto;
}

:root {
  --k-bg-accent: #15803d;
  --k-bg-accent-hover: #16a34a;
  --k-bg-correct: #15803d;
  --k-bg-exist: #ca8a04;
}

:root[data-katla-hc="true"] {
  --k-bg-accent: #f5793a;
  --k-bg-accent-hover: #f6a248;
  --k-bg-correct: #f5793a;
  --k-bg-exist: #85c0f9;
}

.bg-correct {
  background-color: var(--k-bg-correct);
}

.bg-exist {
  background-color: var(--k-bg-exist);
}

.bg-accent {
  background-color: var(--k-bg-accent);
}

.bg-ig {
  background: #f09433;
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
}

.color-accent {
  color: var(--k-bg-accent);
}

.color-accent:hover {
  color: var(--k-bg-accent-hover);
}

.text-dynamic {
  font-size: clamp(0.5rem, min(3vh, 3vw), 1.5rem);
}

@media (min-height: 550px) {
  .text-dynamic {
    font-size: clamp(1rem, min(5vh, 5vw), 2.25rem);
  }
}

@keyframes bounce {
  0%,
  20% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  50% {
    transform: translateY(5px);
  }

  60% {
    transform: translateY(-15px);
  }

  80% {
    transform: translateY(2px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translateX(-1px);
  }

  20%,
  80% {
    transform: translateX(2px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-4px);
  }

  40%,
  60% {
    transform: translateX(4px);
  }
}

@keyframes flip {
  0% {
    transform: rotateX(0);
  }
  50% {
    transform: rotateX(-90deg);
  }
  100% {
    transform: rotateX(0);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-down[data-reach-menu-list],
.slide-down[data-reach-menu-items] {
  left: -16px;
  animation: slide-down 0.2s ease;
}
